import React from 'react'
import {
  Show,
  TabbedShowLayout,
  Datagrid,
  TextField,
  DateField,
  ReferenceManyField,
  ImageField,
  Edit,
  Create,
  CreateButton,
  SimpleForm,
  TextInput,
  required,
  ImageInput,
  useRecordContext,
  useUnique,
  regex,
  Validator,
} from 'react-admin'
import constants from 'utils/constants'

import EditToolbar from 'admin/components/EditToolbar'
import List from 'admin/components/List'

const CreateAgentInAgencyButton = () => {
  const record = useRecordContext()
  if (!record) return null
  return <CreateButton resource="agents" state={{ record: { agency_ids: [record.id] } }} />
}

export const AgenciesShow = () => (
  <Show>
    <TabbedShowLayout>
      <TabbedShowLayout.Tab label="Summary">
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="address" />
        <TextField source="phone_number" />
        <ImageField source="logo.src" />
        <DateField source="created_at" />
        <DateField source="updated_at" />
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="Agents">
        <ReferenceManyField label="Agents" reference="agents" target="agency_ids">
          <CreateAgentInAgencyButton />
          <Datagrid bulkActionButtons={false} rowClick="show">
            <TextField source="first_name" />
            <TextField source="last_name" />
            <TextField source="email" />
            <DateField source="created_at" />
            <DateField source="updated_at" />
          </Datagrid>
        </ReferenceManyField>
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </Show>
)

export const AgenciesList = () => (
  <List>
    <Datagrid bulkActionButtons={false} rowClick="show">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="address" />
      <TextField source="phone_number" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
    </Datagrid>
  </List>
)

const AgencyInputs = () => {
  const unique = useUnique()

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const validateName = regex(
    /^[\wàâäéèêëîïôöûüÿç -]+$/iu,
    "Le nom de l'agence ne doit pas contenir de caractères spéciaux"
  ) as Validator

  return (
    <>
      <TextInput source="name" validate={[required(), unique(), validateName]} />
      <TextInput source="address" validate={required()} />
      <TextInput source="phone_number" />
      <ImageInput
        id="logo"
        source="logo"
        label={`Logo (png image, max size: ${constants.MAX_LOGO_SIZE / 1_024} kB)`}
        maxSize={constants.MAX_LOGO_SIZE}
        accept={{ 'image/png': ['.png'] }}
        validate={required()}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
    </>
  )
}

export const AgenciesCreate = () => (
  <Create redirect="show">
    <SimpleForm sanitizeEmptyValues>
      <AgencyInputs />
    </SimpleForm>
  </Create>
)

export const AgenciesEdit = () => (
  <Edit mutationMode={constants.IS_TEST_ENV ? 'pessimistic' : 'undoable'}>
    <SimpleForm toolbar={<EditToolbar />} sanitizeEmptyValues>
      <AgencyInputs />
    </SimpleForm>
  </Edit>
)

type Agency = {
  id: string
  name: string
  address: string
  phone_number?: string
  logo: { src: string; rawFile?: File } // rawFile is only used when editing/creating
}

// Used to upload the logo when editing or creating an agency
export const agencyParamToFormData = (data: Omit<Agency, 'id'>) => {
  const formData = new FormData()
  formData.append('name', data.name)
  formData.append('address', data.address)
  if (data.phone_number) {
    formData.append('phone_number', data.phone_number)
  }
  if (data.logo.rawFile) {
    formData.append('logo', data.logo.rawFile)
  }
  return formData
}

export const agenciesRecordRepresentation = (record: Agency) => `${record.name} (${record.id})`
