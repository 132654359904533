import * as amplitude from '@amplitude/analytics-browser'
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser'
import { type Website } from 'types/common'
import constants from './constants'
import { isStagingDeployment, isTestEnv } from './environment'

const LOCAL_STORAGE_USER_ID = 'amp_user_id'
const LOCAL_STORAGE_TRACKING_EVENTS_KEY = 'tracking_events'

const sessionReplayTracking = sessionReplayPlugin({ sampleRate: 1 })
amplitude.add(sessionReplayTracking)

export const initAmplitude = (website: Website) => {
  if (isTestEnv()) {
    return
  }
  if (!constants.AMPLITUDE_API_KEY) {
    return
  }
  if (website === 'admin') {
    return
  }

  amplitude.init(constants.AMPLITUDE_API_KEY, {
    defaultTracking: website === 'employee',
  })
}

export const setUserId = (id: string) => {
  amplitude.setUserId(id)
  if (isTestEnv()) {
    localStorage.setItem(LOCAL_STORAGE_USER_ID, id)
  }
}

type EventProperties = {
  documentGroupId?: string
  documentGroupSlug?: string
  documentSlug?: string
  agencyId?: string
  uploadType?: 'camera' | 'file'
  fileType?: string
}

type Event = {
  name: string
} & EventProperties

type StoredTrackingEvents = {
  events: Event[]
}

const retrievePreviousTrackingEvents = (): Event[] => {
  const rawPreviousTrackingEvents = localStorage.getItem('tracking_events')

  if (!rawPreviousTrackingEvents) {
    return []
  }

  const parsedPreviousTrackingEvents = JSON.parse(rawPreviousTrackingEvents) as StoredTrackingEvents

  return parsedPreviousTrackingEvents.events
}

export const trackEvent = (eventName: string, eventProperties: EventProperties = {}) => {
  if (isTestEnv()) {
    const userId = localStorage.getItem(LOCAL_STORAGE_USER_ID)
    const previousTrackingEvents = retrievePreviousTrackingEvents()

    localStorage.setItem(
      LOCAL_STORAGE_TRACKING_EVENTS_KEY,
      JSON.stringify({ events: [...previousTrackingEvents, { name: eventName, userId, ...eventProperties }] })
    )
  } else if (isStagingDeployment()) {
    console.log('TRACKING EVENT:', eventName, eventProperties) // eslint-disable-line no-console
  } else {
    amplitude.track(eventName, eventProperties)
  }
}
